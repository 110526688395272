
<template>
  <div class="defeatCardItem" >
    <div class="left" @click="$emit('click',itemData)">
      <div class="row">
        <shortConsumerInfo :data="itemData">
        </shortConsumerInfo>
        <!-- 审核状态 -->
        <span v-if="itemData.reviewStatus !== '1013001'" :class="`status-${itemData.reviewStatus}`">{{ getDictNameByCode(1013,
          itemData.reviewStatus) }}</span>
      </div>
      <CardOptions v-if="clientType == 1" :data="itemData"/>
      <div class="info">
        <!-- <span class="label">{{getDictNameByCode(1014, itemData.applyUserRole)}}:</span> -->
        <span class="label">{{$t( clientType == 1 ? '销售顾问' : '中央客服')}}：</span>
        <span class="val"> {{ itemData.applyUserName || '--' }} </span>
        <span> | </span>
        <span class="val"> {{ itemData.failName || '--' }} </span>
      </div>
      <div v-if="itemData.reviewStatus === '1013001'" class="info">
        <span class="label">{{$t('申请时间：')}}</span>
        <span class="val"> {{ itemData.applyTime | timeText }}</span>
      </div>
      <!-- 待审核 -->
      <div v-if="itemData.reviewStatus === '1013001'" class="info">
        <span class="label">{{$t('审核截止时间:')}}</span>
        <span class="val">
          {{ itemData.planReviewTime | timeText('MM-DD') }}
          <span v-if="!isAfterCurrentTime(itemData.planReviewTime)" class="yq">{{$t('逾期')}}</span>
        </span>
      </div>
      <!-- 已审核 -->
      <div v-else class="info">
        <span class="label">{{$t('审核信息:')}}</span>
        <span class="val">
          <span> {{ itemData.reviewTime | timeText }}</span>
          <span v-if="itemData.reviewTime && itemData.reviewRemark"> | </span>
          <span > {{ itemData.reviewRemark }}</span>
        </span>
      </div>
    </div>
    <div v-if="itemData.reviewStatus === '1013001'" class="right">
      <van-checkbox :value="itemData.checked" shape="square" @click.stop="onClick"></van-checkbox>
    </div>
  </div>
  </template>
<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import CardOptions from '@/components/card-view/cardOptions.vue'
import { dateFormat } from '@/utils'
export default {
  name: 'DefeatClueItem',
  components: {
    shortConsumerInfo, CardOptions
  },
  filters: {
    timeText(data, str = 'MM-DD HH:mm') {
      if (!data) return '--'
      const year = data.split('-')[0]
      const theYear = new Date().getFullYear()
      if (year!=theYear){
        return dateFormat(data, 'YYYY-' + str)
      } else {
        return dateFormat(data, str)
      }
    }
  },
  props: {
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
  
  },
  
  data() {
    return {
  
    }
  },
  computed: {
    clientType() {
      return this.$store.getters.userInfo.type
    }
  },
  methods: {
  
    /**
       *
       * @param {*} inputTime 传入时间
       * @returns {Boolean} 是否在当前时间之后
       */
    isAfterCurrentTime(inputTime) {
      if (!inputTime) return false
      const currentTime = new Date()
      const inputDate = new Date(inputTime)
  
      return inputDate > currentTime
    },
  
    onClick() {
      this.$emit('change', !this.itemData.checked, { ...this.itemData })
    },
  
  }
}
</script>
  <style lang="less" scoped>
  @checkbox-color: #0D171A;
  
  .defeatCardItem {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 16px 12px 12px 12px;
  
    .left {
      flex: 1;
      color: @checkbox-color;
      font-size: 13px;
      line-height: 16px;
  
      .info {
        margin-bottom: 8px;
        word-break:break-all;
        word-wrap:break-word;
        white-space:pre-wrap;
  
        .label {
          color: rgba(13, 23, 26, 0.45);
        }
  
        .val {
          white-space: normal;
  
          .yq {
            margin-left: 12px;
            font-size: 13px;
            font-weight: 500;
            color: #E4002C;
          }
        }
  
      }
  
      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(13,23,26,0.05);
        .status-1013002{
          color: #029640;
        }
        .status-1013003 {
          color: #FA5410;
        }
        .status-1013004{
          color: #0040C5;
        }
  
        .dj {
          background: #EED484;
          border-radius: 1px 1px 1px 1px;
          padding: 0px 4px;
          color: #FFFFFF;
          font-size: 12px;
          margin-left: 12px;
        }
      }
    }
  
    .right {
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      /deep/.van-checkbox__icon {
        font-size: 16px;
  
        .van-icon {
          border: 2px solid rgba(13,23,26,0.45);
        }
      }
  
      /deep/.van-checkbox__icon--checked .van-icon {
        background-color: @checkbox-color;
        border-color: @checkbox-color;
        line-height: 1;
      }
    }
  }
  </style>
  